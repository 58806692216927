import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import { loginService } from '../../services/auth';
import { useTheme } from 'context/ThemeContext';
import { useNavigate } from 'react-router-dom';

import {FormattedMessage} from 'react-intl'
import { LanguageDropdown } from '_i18n/LanguageDropdown';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://necto.com.br/" target="_blank">
        IAMarketing
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
    const navigate = useNavigate();
    const {theme, setTheme} = useTheme();

    const [error, setError] = React.useState<string>('');
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email') as string;
        const password = formData.get('password') as string;
        loginService(email|| '', password|| '').then(res => {
            if (res.status === 200 && 'status_code' in res.data && res.data.status_code !== 200) {
                setError(res.data.detail?.message || 'Error')
            } else {
                return navigate('/')
            }
        }).catch(err => {
            console.log('err', err)
        })
    };


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{ marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
                <LanguageDropdown />
                <Button onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
                    {theme === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                </Button>
            </Box>
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                <FormattedMessage id="sign_in" defaultMessage="Sign In"/>
            </Typography>
            {error && (
                <Alert icon={<CloseIcon fontSize="inherit" />} severity="error">
                    {error}
                </Alert>
            )}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} data-testid="login-form">
                <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={<FormattedMessage id="email" defaultMessage="Email Address"/>}
                name="email"
                autoComplete="email"
                autoFocus
                />
                <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={<FormattedMessage id="password" defaultMessage="Password"/>}
                type="password"
                id="password"
                autoComplete="current-password"
                />
                <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={<FormattedMessage id="remember_me" defaultMessage="Remember me"/>}
                />
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                <FormattedMessage id="sign_in" defaultMessage="Sign In"/>
                </Button>
            </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}

export default Login