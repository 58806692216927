import React from "react"
import {Typography, Link, Card, CardContent} from '@mui/material'
import { iBusinessProfile } from "../types/business.type"
import { ContrastTextBox } from "pages/styled/Common.styled"
import { FormattedMessage } from "react-intl"


type IBusinessProfileComponent = {
    profile?: iBusinessProfile,
    files?: string[],
}

const BusinessProfile: React.FC<IBusinessProfileComponent> = ({profile, files}) => {

    const getFileLink = (file: string) => {
        if ('gs://' === file.slice(0, 5)) {
            return `https://storage.googleapis.com/${file.replace('gs://', '')}`
        }
        return file
    }

    const getFileName = (file: string) => {
        return file.split('/').pop()
    }

    return (
        <ContrastTextBox>
            <Card>
                <CardContent>
                    <Typography fontWeight={700} variant="h5"><FormattedMessage id="culture" defaultMessage={"Culture"} /></Typography>
                    <Typography variant="h6"><FormattedMessage id="brand" defaultMessage={"Brand"} />:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.brand}</Typography>

                    <Typography variant="h6"><FormattedMessage id="mission" defaultMessage={"Mission"} />:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.mission}</Typography>

                    <Typography variant="h6"><FormattedMessage id="positioning" defaultMessage={"Positioning"} />:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.positioning}</Typography>

                    <Typography variant="h6"><FormattedMessage id="purpose" defaultMessage={"Purpose"} />:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.purpose}</Typography>

                    <Typography variant="h6"><FormattedMessage id="voice" defaultMessage={"Voice"} />:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.voice}</Typography>
                </CardContent>
            </Card>

            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5"><FormattedMessage id="product" defaultMessage={"Product"} /></Typography>
                    <Typography variant="h6"><FormattedMessage id="products" defaultMessage={"Products"} />:</Typography>
                    {profile?.Product?.products?.map((product, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{product}</Typography>
                    ))}
                    
                    <Typography variant="h6"><FormattedMessage id="value_proposition" defaultMessage={"Value Proposition"} />:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Product?.value_proposition}</Typography>

                    <Typography variant="h6"><FormattedMessage id="industry" defaultMessage={"Industry"} />:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Product?.industry}</Typography>

                    <Typography variant="h6"><FormattedMessage id="pain_points" defaultMessage={"Pain Points"} />:</Typography>
                    {profile?.Product?.pain_points?.map((painPoints, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{painPoints}</Typography>
                    ))}

                    <Typography variant="h6"><FormattedMessage id="business_story" defaultMessage={"Business Story"} />:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Product?.business_story}</Typography>

                    <Typography variant="h6"><FormattedMessage id="content_type" defaultMessage={"Content Type"} />:</Typography>
                    {profile?.Product?.content_type?.map((content, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{content}</Typography>
                    ))}
                </CardContent>
            </Card>
            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5"><FormattedMessage id="business" defaultMessage={"Business"} /></Typography>
                    <Typography variant="h6"><FormattedMessage id="target_market" defaultMessage={"Target Market"} />:</Typography>
                    {profile?.Business?.target_market?.map((target, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{target}</Typography>
                    ))}

                    <Typography variant="h6"><FormattedMessage id="website" defaultMessage={"Website"} />:</Typography>
                    <Link sx={{marginLeft: 2}} href={'http://' + profile?.Business?.website || '#'} target="_blank">{profile?.Business?.website}</Link>
                </CardContent>
            </Card>
            <Card sx={{marginTop: 2}}>
                <CardContent>

                    <Typography fontWeight={700} variant="h5"><FormattedMessage id="competitors" defaultMessage={"Competitors"} /></Typography>

                    <Typography variant="h6"><FormattedMessage id="competitors" defaultMessage={"Competitors"} />:</Typography>
                    {profile?.Competitors?.competitors?.map((competitors, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{competitors}</Typography>
                    ))}

                    <Typography variant="h6"><FormattedMessage id="efforts" defaultMessage={"Efforts"} />:</Typography>
                    {profile?.Competitors?.efforts?.map((effort, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{effort}</Typography>
                    ))}
                </CardContent>
            </Card>

            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5"><FormattedMessage id="current" defaultMessage={"Current"} /></Typography>
                    <Typography variant="h6"><FormattedMessage id="SEO" defaultMessage={"SEO"} />:</Typography>
                    <Typography sx={{marginLeft: 2}}>{profile?.Current?.SEO}</Typography>

                    {/* <Typography variant="h6">Current efforts:</Typography>
                    {profile?.Current?.current_efforts?.map((effort, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{effort}</Typography>
                    ))} */}

                    <Typography variant="h6"><FormattedMessage id="whats_working" defaultMessage={"What's Working"} />:</Typography>
                    {profile?.Current?.whats_working?.map((work, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{work}</Typography>
                    ))}
                </CardContent>
            </Card>

            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5" marginBottom={1}><FormattedMessage id="goals" defaultMessage={"Goals"} /></Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Goals?.goals}</Typography>
                </CardContent>
            </Card>
            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5" marginBottom={1}>Files</Typography>
                    {files?.map((file, index) => (
                        <Link key={index} sx={{marginLeft: 2}} href={getFileLink(file)} target="_blank">{getFileName(file)}</Link>
                    ))}
                </CardContent>
            </Card>
        </ContrastTextBox>
    )
} 
export default BusinessProfile