import pt_br from './languages/pt_br';
import en from './languages/en';

export const LANGUAGES: {[key: string]: {[key: string]: string}} = {
    'pt-br':{...pt_br},
    'en':{...en}
}
export const DEFAULT_LANGUAGE = 'en';

export const LangaugeSelections = [
    {value: 'pt-br', label: 'Português'},
    {value: 'en', label: 'English'},
]