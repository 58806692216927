import React from 'react';
import { LANGUAGES, DEFAULT_LANGUAGE } from './languages';
import { useTheme } from 'context/ThemeContext';
import { IntlProvider } from 'react-intl';

export const IntlProviderConfigured: React.FC<{children: string | JSX.Element | JSX.Element[]}> = ({ children })  => {
    const { language } = useTheme()
    return (
    <IntlProvider messages={LANGUAGES[language]} locale={DEFAULT_LANGUAGE} defaultLocale={DEFAULT_LANGUAGE}>
        {children}
    </IntlProvider>
    )
}