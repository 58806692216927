import React, { createContext, useContext } from 'react';
import { Alert, createTheme, Snackbar, ThemeProvider } from '@mui/material';
import Cookies from 'universal-cookie';
import {DEFAULT_LANGUAGE} from '../_i18n/languages';

const cookies = new Cookies();

const ThemeContext = createContext({ 
    theme: 'light', 
    setTheme: (theme: 'light' | 'dark') => {},
    openSnackBar: (message: string|JSX.Element) => {},
    language: DEFAULT_LANGUAGE,
    setLanguage: (language: string) => {},
});

const defaultTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#0b488a',
        },
    },
});
const darkTheme = createTheme({ palette: { mode: 'dark' } });

const cookietheme = cookies.get('iamarketing-theme') || 'light';

export const ThemeContextProvider: React.FC<{children: string | JSX.Element | JSX.Element[]}> = ({ children }) => {
    const [theme, setTheme] = React.useState<'light' | 'dark'>(cookietheme); // Tema inicial
    const [language, setLanguage] = React.useState<string>(cookies.get('iamarketing-language') || DEFAULT_LANGUAGE);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState<string | JSX.Element>('');

    const openSnackBar = (message: string|JSX.Element) => {
        setSnackMessage(message);
        setSnackOpen(true);
    }

    React.useEffect(() => {
        const theme = cookies.get('iamarketing-theme') || 'light';
        setTheme(theme)
    }, [])

    React.useEffect(() => {
        cookies.set('iamarketing-language', language, { path: '/' });
    }, [language])

    React.useEffect(() => {
        cookies.set('iamarketing-theme', theme, { path: '/' });
    }, [theme])


    return (
        <ThemeContext.Provider value={{ theme, setTheme, openSnackBar, language, setLanguage }}>
            <Snackbar 
                open={snackOpen} 
                autoHideDuration={6000}
                onClose={() => setSnackOpen(false)}
                ClickAwayListenerProps={{ onClickAway: () => null }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackOpen(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackMessage}
                </Alert>
            </Snackbar>
            <ThemeProvider theme={theme === 'dark' ? darkTheme : defaultTheme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
