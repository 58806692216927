import React from 'react';
import Grid from '@mui/material/Grid';
import Base from '../Base';
import { Info, ExpandMore } from '@mui/icons-material';
import { Alert, Box, Button, styled, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import GeneratedGroupData from 'components/GeneratedGoupData';
import { getPostApi } from "services/generate";
import { useNavigate, useLoaderData, Link, useParams } from 'react-router-dom';

import {FormattedMessage} from 'react-intl'

const RegisterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    padding: '0 0.5rem',
}))


const Posts = () => {
    let { userId } = useParams();
    const navigate = useNavigate();
    const posts = useLoaderData() as any
    const [postsContent, setPostsContent] = React.useState<any>(posts.contents);

    const changeAccordion = async (i:number) => {
        if (postsContent[i] === undefined) {
            const res = await getPostApi(posts.files[i])
            postsContent[i] = res.data
            setPostsContent([...postsContent])    
        }
    }

    const gotToGeneratePost = () => {
        navigate(`/generate/${userId}/post`)
    }

    const filenameToDate = (filename:string) => {
        const tsMatch = filename.match(/\/(\d+)\.json/)
        if (tsMatch != null){
            const ts = parseInt(tsMatch[1])
            const dt = new Date(ts * 1000).toLocaleString()
            return dt    
        }
        return filename
    }

    return (
        <Base name={<FormattedMessage id="posts" />} noBackButton>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', paddingY: 2, paddingX: 10 }}>
                <Button variant="contained" onClick={gotToGeneratePost}><FormattedMessage id="generate_post" /></Button>
            </Box>
            <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {!posts.files.length ? (
                    <Grid item xs={12} padding={2} >
                    <Alert sx={{ display: 'flex', justifyContent: 'center'}} icon={<Info fontSize="inherit" />} severity="info">
                        No posts saved
                        <RegisterLink to="/profile">
                        Register here
                        </RegisterLink>
                    </Alert>
                    </Grid>
                ): (
                posts.files.map((postfile: string, index: number) => (
                    <Grid item xs={12} padding={2} >
                        <Accordion id={`posts-${index}`} defaultExpanded={index === 0} onChange={() => changeAccordion(index)}>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            >
                            {filenameToDate(postfile)}
                            </AccordionSummary>
                            <AccordionDetails>
                                {postsContent[index] && 
                                <>
                                    {Object.keys(postsContent[index])
                                        .filter((key: string) => !['questions', 'slides_captions'].includes(key))
                                        .map(key => <GeneratedGroupData key={key} data={postsContent[index][key]} tag={key}/>)}
                                </>}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )))}
            </Grid>
        </Base>
    )
}

export default Posts