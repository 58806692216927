import React from "react";
import { 
    Alert,
    Box, Button, CircularProgress, FormControl, Grid, InputLabel, 
    MenuItem, Select, TextField 
} from "@mui/material"
import Base from "pages/Base"
import { businessProfileApi, createProfileApi, updateProfileApi } from "services/users";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import DragDrop from "../../components/DragDrop";
import { AxiosResponse } from "axios";
import BusinessProfile from "components/BusinessProfile";
import { iBusinessProfile } from "types/business.type";
import { iProfile } from "types/user.type";
import { useTheme } from 'context/ThemeContext';

import {FormattedMessage, useIntl} from 'react-intl'


const ProfileForm: React.FC = () => {
    const intl = useIntl();
    const { openSnackBar } = useTheme();
    const navigate = useNavigate();
    const profileData = useLoaderData() as {profile: iProfile, business_profile: iBusinessProfile}
    const businessProfile = profileData?.business_profile
    const [loading, setLoading] = React.useState<boolean>(false)
    const [hasError, setHasError] = React.useState<boolean>(false)
    const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);
    const [files, setFiles] = React.useState<String[]>([]);
    const [profile, setProfile] = React.useState<iProfile>(profileData?.profile || {
        name: '',
        email: '',
        profile_type: '',
        webhook: [{
            webhook: '',
            name: '',
        }],
    });
    const types = [
        {label: 'Personal', value: 'personal'},
        {label: 'Business', value: 'business'},
    ]

    const onSendData = (res: AxiosResponse) => {
        // if have files to upload, set message
        let message = uploadedFiles.length ? 'profile_saved_reading_files' : 'profile_saved'
        openSnackBar(intl.formatMessage({id: message}))
        if (!uploadedFiles.length) {
            navigate('/')
            return
        }
        const id = profile?.id || res.data.id
        const formData = new FormData();
        formData.append('file', uploadedFiles[0]);
        businessProfileApi(id, formData).then(() => {
            openSnackBar(intl.formatMessage({id: 'success_uploaded'}))
            navigate('/')
        }).catch(err => {
            console.log('err', err)
            setLoading(false)
            setHasError(true)
        })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true)
        setHasError(false)
        const urlParams = new URLSearchParams({
            name: profile?.name,
            email: profile?.email,
            profile_type: profile?.profile_type,
        }).toString();
        if (profile?.id){
            updateProfileApi(profile?.id, urlParams).then(onSendData)
        } else {
            createProfileApi(urlParams).then((res) => {
                setProfile({
                    ...profile,
                    id: res.data.id
                })
                onSendData(res)
            }).catch(err => {
                console.log('err', err.response)
                setLoading(false)
                setHasError(true)
            })
        }
    }

    const handleUpload = (filesList: FileList) => {
        setFiles(files.concat(Array.from(filesList).map(file => file.name)))
        setUploadedFiles(Array.from(filesList))
    }


    return (
        <Base name={<FormattedMessage id="profile" />}>
            { profile?.id && !loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', paddingX: 10 }}>
                <Link to={`/generate/${profile?.id}/post`}>
                    <Button fullWidth variant="contained"><FormattedMessage id="generate_post" defaultMessage="Generate Post" /></Button>
                </Link>
            </Box>
            ) : <></>}
            {hasError ? (
                <Box paddingX={5}>
                    <Alert severity="error"><FormattedMessage id="error_save" defaultMessage="Error saving data. Please try again." /></Alert>
                </Box>
            ): <></>}
            <Box
                sx={{
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
                >
                <Grid container>
                    <Grid item xs={12} md={businessProfile ? 4 : 12}>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <Grid container columnSpacing={1} spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label={<FormattedMessage id="name" defaultMessage="Name" />}
                                        value={profile?.name || ''}
                                        onChange={(e) => setProfile(() => ({...profile, name: e.target.value}))}
                                        name="profileName"
                                        autoFocus
                                    />
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel id="select-type"><FormattedMessage id="type" defaultMessage="Type" /></InputLabel>
                                                <Select
                                                    labelId="select-type"
                                                    id="demo-simple-select"
                                                    name="type2"
                                                    value={profile?.profile_type || ''}
                                                    onChange={(e) => setProfile(() => ({...profile, profile_type: e.target.value}))}
                                                    label={<FormattedMessage id="type" defaultMessage="Type" />}
                                                >
                                                    {types.map((t, i) => (
                                                        <MenuItem key={i} value={t.value}>{t.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <DragDrop onUpload={handleUpload} uploadedFiles={uploadedFiles} setFiles={setUploadedFiles} files={files}></DragDrop>
                                </Grid>
                            </Grid>
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>
                            {loading ? (
                                <>
                                <FormattedMessage id="saving" defaultMessage="Saving" /> <CircularProgress color="inherit" size={18} sx={{marginLeft: 1}}/>
                                </>
                            ): <><FormattedMessage id="save" defaultMessage="Save" /></> }
                            </Button>
                        </Box>
                    </Grid>
                        
                    {businessProfile && (
                        <Grid item xs={12} md={8}>
                            <Box px={{sm: 0, md: 5}}>
                                <BusinessProfile profile={businessProfile} files={profileData?.profile?.profile || []} ></BusinessProfile>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
            
        </Base>
    )
}

export default ProfileForm