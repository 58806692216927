import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { User } from 'types/user.type';
import { Link, useNavigate } from 'react-router-dom';
import {FormattedMessage} from 'react-intl'

type iProfileCard = {
    user: User
}
const ProfileCard: React.FC<iProfileCard> = ({user}) => {
    const navigate = useNavigate();

    function stringToColor(string: string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
    
        let color = '#';
    
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }

    function stringAvatar(name: string) {
        const nameSplitted = name.split(' ');
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${nameSplitted[0][0]}${ nameSplitted.length > 1 ? nameSplitted[1][0] : ''}`,
        };
    }

    const handleEditProfile = (id: string) => {
        navigate(`/profile/${id}`)
    }

    return (
        <Box sx={{ margin: 2 }}>
            <Card>
                <CardContent>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item sm={2}>
                            <Avatar {...stringAvatar(user.name)} />
                        </Grid>
                        <Grid item sm={8}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item sm={12}>
                                    <Typography variant="h5">{user.name}</Typography>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography>{user.profile_type}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={2}>
                            <Button onClick={() => handleEditProfile(user.id)}>
                                <EditNoteIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container sx={{mt: 2}}>
                        <Grid item sm={6}>
                            <Link to={`/posts/${user.id}`}>
                                <Button fullWidth><FormattedMessage id="posts" /></Button>
                            </Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to={`/generate/${user.id}/post`}>
                                <Button fullWidth variant="contained"><FormattedMessage id="generate_post" /></Button>
                            </Link>
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}

export default ProfileCard