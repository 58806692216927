const language = {
    'login':'Login',
    'sign_in':'Sign in',
    'email':'Email Address',
    'password':'Password',
    'remember_me':'Remember me',
    'logout':'Logout',
    'generate_post':'Generate Post',
    'add_profile':'Add Profile',
    'profiles':'Profiles',
    'profile':'Profile',
    'posts':'Posts',
    'name':'Name',
    'type':'Type',
    'delete':'Delete',
    'drop_files':'Drop some files here',
    'save':'Save',
    'saving':'Saving',
    'error_save':'Error saving data. Please try again.',
    'profile_saved': 'Profile saved successfully.',
    'profile_saved_reading_files': 'Profile saved successfully. Reading files...',
    'success_uploaded': 'File uploaded successfully.',
    'culture': 'Culture',
    'brand': 'Brand',
    'mission': 'Mission',
    'positioning': 'Positioning',
    'purpose': 'Purpose',
    'voice': 'Voice',
    'product': 'Product',
    'products': 'Products',
    'value_proposition': 'Value Proposition',
    'industry': 'Industry',
    'pain_points': 'Pain Points',
    'business_story': 'Business Story',
    'content_type': 'Content Type',
    'business': 'Business',
    'target_market': 'Target Market',
    'website': 'Website',
    'competitors': 'Competitors',
    'efforts': 'Efforts',
    'current': 'Current',
    'SEO': 'SEO',
    'whats_working': 'What we are working on',
    'goals': 'Goals',
    'files': 'Files',
    'quotes': 'Quotes',
    'memes': 'Memes',
    'fun_facts': 'Fun Facts',
    'benefits_features': 'Benefits & Features',
    'q_a': 'Q&A',
    'humanity_stories': 'Humanity Stories',
    'educational_carousel': 'Educational Carousel',
    'call_to_actions': 'Call To Actions',
    'testimonials_reel': 'Testimonial Reel',
    'testimonials_post': 'Testimonial Post',
    'inspirational': 'Inspirational',
    'reel_canvas': 'Reel Canvas',
    'videos_benefits': 'Videos & Benefits',
    'tone': 'Tone',
    'number_of_posts': 'Number of Posts',
    'location_business': 'Location of Business',
    'locations': 'Locations',
    'enter_locations': 'Enter the locations separated by commas',
    'loading': 'Loading...',
    'generate': 'Generate',
}

export default language