import React from 'react';
import { useTheme } from 'context/ThemeContext';
import { Button, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import {LangaugeSelections} from '_i18n/languages';

export const LanguageDropdown: React.FC<{color?: string}> = ({color='primary'}) => {
    const { setLanguage } = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (selectedLanguage: string) => {
        setLanguage(selectedLanguage);
        handleClose();
    }   

    return (
        <>
        <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{color}}
        >
            <LanguageIcon />
        </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {LangaugeSelections.map(({value, label}) => (
                    <MenuItem key={value} onClick={() => handleChange(value)}>{label}</MenuItem>
                ))}
            </Menu>
        </>
    )
}