import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Drawer from '@mui/material/Drawer'; 
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Menu from 'components/Menu';
import { useTheme } from 'context/ThemeContext';
import { styled } from '@mui/material';

import { LanguageDropdown } from '_i18n/LanguageDropdown';
import {FormattedMessage} from 'react-intl'

type IBase = {
    children: string | JSX.Element | JSX.Element[],
    name?: string|JSX.Element,
    noBackButton?: boolean
}

const Footer = styled(Toolbar)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey['900'] : theme.palette.grey['100'],
    color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
}))


const Base: React.FC<IBase> = ({children, name, noBackButton=false}: IBase) => {
    const cookies = new Cookies();
    const { theme, setTheme } = useTheme()
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setOpen(open);
    }
    const handleLogout = () => {
        cookies.remove('iamarketing-user-token')
        navigate('/login')
    }

    const handleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark')
    }

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {name}
                </Typography>
                <Box>
                    <LanguageDropdown color='white' />
                    <Button color="inherit" onClick={handleTheme}>
                        {theme === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                    </Button>
                    <Button color="inherit" onClick={handleLogout}>
                        <FormattedMessage id="logout" />
                    </Button>
                </Box>
                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <Menu toggleDrawer={toggleDrawer} />
            </Drawer>
        </Box>
        <Box sx={{backgroundColor: 'background.default', minHeight: '82vh', padding: 2}}>
            {noBackButton ? <></> : <Button onClick={handleBack} ><ArrowBackIcon /></Button>}
            {children}
        </Box>
        <Footer variant='dense'>
            <Typography variant="h6" component="div" textAlign={'end'} width={'100%'}>
                IAMarketing - {new Date().getFullYear()}
            </Typography>
        </Footer>
        </>
    )
}

export default Base