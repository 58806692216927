import React, { DragEvent } from "react"

import { Button, CircularProgress, Grid, Input, styled, Tooltip, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {FormattedMessage} from 'react-intl'

type iDragDrop = {
    onUpload: (event: FileList) => void,
    uploadedFiles?: File[],
    setFiles: (files: File[]) => void,
    files?: String[]
}

const StyledDragDropContainer = styled('div')(({ theme }) => (
    `
    height: 100%;
    min-height: 250px;
    `
))

const StyledDragDrop = styled('div')(({ theme }) => (
    `
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 2rem;
    color: ${theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark};
    border: 2px ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[400]} dashed;
    transition: background-color 0.5s ease;
    border-radius: 12px;
    &.active {
        background-color: ${theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main}CC;
        transition: background-color 0.5s ease;
        button{
            color: ${theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.contrastText};
        }
    }
    .FilesDragAndDrop__file{
        width: 100%;
        height: 100%;
    }
    &:hover {
        cursor: pointer;
        button{
            color: ${theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark};
        }
    }
    `
))

const DragDrop: React.FC<iDragDrop> = ({onUpload, files=[], setFiles, uploadedFiles=[]}) => {
    const drop = React.useRef(null);
    const inputButton = React.useRef<HTMLButtonElement>(null);
    const [dragEvent, setDragEvent] = React.useState<string>('');

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation()
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation()
        setDragEvent('')
        onUpload(event.dataTransfer.files)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = event.target.files;
            setDragEvent('')
            onUpload(files)
        }
    };

    const handleRemoveFile = (event: any, index: number) => {
        event.stopPropagation()
        let filtered: File[] = Array.from(uploadedFiles).filter((file: File, i: number) => i !== index)
        setFiles(filtered)
    }

    const handleClickDragArea = () => {
        if (inputButton?.current) {
            inputButton?.current?.click();
        }
    }

    const checkHasUploaded = (file: File) => {
        return files.some((fileName: String) => fileName.includes(file.name))
    }

    return (
        <StyledDragDropContainer className="FilesDragAndDrop">

            <StyledDragDrop 
                className={dragEvent} 
                ref={drop} 
                onDrop={handleDrop} 
                onDragOver={handleDragOver}
                onDragEnter={e => setDragEvent('active')} 
                onDragLeave={e => setDragEvent('')} 
                onClick={handleClickDragArea} 
            >
            {uploadedFiles.length > 0 ? (
                Array.from(uploadedFiles).map((file: File, index: number) => (
                <Grid container className="FilesDragAndDrop__file" sx={{padding: 1}} key={index}>
                    <Grid item xs={11} sx={{paddingX: 1, paddingY: 0.5}}>
                        <Typography textAlign={"left"}>
                        {!checkHasUploaded(file) && <CircularProgress size={12} sx={{marginX: 0.5}}/>}
                            {file.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                    <Tooltip title={<FormattedMessage id="delete" defaultMessage={"Delete"}/>}>
                        <DeleteIcon fontSize="small" onClick={(e) => handleRemoveFile(e, index)}/>
                    </Tooltip>
                    </Grid>
                </Grid> 
                ))):(
                <Button onClick={handleClickDragArea} sx={{width: '100%'}}>
                    <FormattedMessage id="drop_files" defaultMessage={"Drop some files here"}/>
                </Button>
                )}

                <div className="FilesDragAndDrop__button">
                    <Input type="file" inputRef={inputButton} onChange={handleFileChange} sx={{display: 'none'}} />
                </div>
            </StyledDragDrop>
        </StyledDragDropContainer>
    )
}

export default DragDrop