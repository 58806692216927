import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ProtectedRoutes from './pages/ProtectedRoutes';
import Login from './pages/login/Login';
import Profiles from './pages/profiles/Profiles';
import ProfileForm from 'pages/profiles/ProfileForm';
import { userChatKeyworkdsLoaders, userChatLoaders, userDataLoader, userLoader, usersLoader, postsLoader } from 'services/loaders';
import GeneratePost from 'pages/Generate/GeneratePost';
import Chat from 'pages/chat/Chat';
import GenerateArticle from 'pages/Generate/GenerateArticle';
import Posts from 'pages/posts/Posts';
import { ThemeContextProvider } from 'context/ThemeContext';

import { IntlProviderConfigured } from '_i18n/IntlProviderConfigured';


const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login/>,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "",
        element: <Profiles/>,
        loader: usersLoader
      },
      {
        path: '/profile',
        element: <ProfileForm/>
      },
      {
        path: '/profile/:id',
        element: <ProfileForm/>,
        loader: ({ params }) => userLoader(params?.id || '')
      },
      {
        path: 'generate/:userId',
        children: [
          {
            path: 'post/',
            element: <GeneratePost/>,
            loader: ({ params }) => userDataLoader(params?.userId || '')
          },
          {
            path: 'article/',
            element: <GenerateArticle/>,
            loader: ({ params }) => userChatKeyworkdsLoaders(params?.userId || ''),
            
          },
          {
            path: 'post/:chatId',
            element: <GeneratePost/>,
            loader: ({ params }) => userDataLoader(params?.userId || '')
          },
          {
            path: 'article/:chatId',
            element: <GenerateArticle/>,
            loader: ({ params }) => userChatKeyworkdsLoaders(params?.userId || '', params?.chatId || '')
          }
        ]
      },
      {
        path: '/chat/:userId',
        element: <Chat/>,
        loader: ({ params }) => userChatLoaders(params?.userId || '')
      },
      {
        path: '/posts/:userId',
        element: <Posts/>,
        loader: ({ params }) => postsLoader(params?.userId || '')
      }
  ]}
]);

function App() {
  return (
    <ThemeContextProvider>
      <IntlProviderConfigured>
        <RouterProvider router={router} />
      </IntlProviderConfigured>
    </ThemeContextProvider>
  );
}

export default App;
