const language = {
    'login':'Login',
    'sign_in':'Entrar',
    'email':'E-mail',
    'password':'Senha',
    'remember_me':'Lembrar-me',
    'logout':'Sair',
    'generate_post':'Gerar Post',
    'add_profile':'Adicionar Perfil',
    'profiles':'Perfis',
    'profile':'Perfil',
    'posts':'Posts',
    'name':'Nome',
    'type':'Tipo',
    'delete':'Excluir',
    'drop_files':'Arraste os arquivos aqui',
    'save':'Salvar',
    'saving':'Salvando',
    'error_save':'Erro ao salvar dados. Por favor, tente novamente.',
    'profile_saved': 'Perfil salvo com sucesso.',
    'profile_saved_reading_files': 'Perfil salvo com sucesso. Lendo arquivos...',
    'success_uploaded': 'Arquivo enviado com sucesso.',
    'culture': 'Cultura',
    'brand': 'Marca',
    'mission': 'Missão',
    'positioning': 'Posicionamento',
    'purpose': 'Propósito',
    'voice': 'Voz',
    'product': 'Produto',
    'products': 'Produtos',
    'value_proposition': 'Proposta de Valor',
    'industry': 'Industria',
    'pain_points': 'Pontos de Poder',
    'business_story': 'História de Negócios',
    'content_type': 'Tipo de Conteúdo',
    'business': 'Negócio',
    'target_market': 'Mercado Alvo',
    'website': 'Website',
    'competitors': 'Concorrentes',
    'efforts': 'Efeitos',
    'current': 'Atual',
    'SEO': 'SEO',
    'whats_working': 'O que estamos fazendo',
    'goals': 'Objetivos',
    'files': 'Arquivos',
    'quotes': 'Citações',
    'memes': 'Memes',
    'fun_facts': 'Fatos divertidos',
    'benefits_features': 'Benefícios e recursos',
    'q_a': 'Perguntas e Respostas',
    'humanity_stories': 'Histórias da Humanidade',
    'educational_carousel': 'Carrossel Educacional',
    'call_to_actions': 'Chamada para ação',
    'testimonials_reel': 'Reel de Testemunhos',
    'testimonials_post': 'Post de Testemunhos',
    'inspirational': 'Inspiracional',
    'reel_canvas': 'Reel Canvas',
    'videos_benefits': 'Vídeos e Benefícios',
    'tone': 'Enfase',
    'number_of_posts': 'Número de Posts',
    'location_business': 'Local do Negócio',
    'locations': 'Localizações',
    'enter_locations': 'Digite as localizações separadas por vírgulas',
    'loading': 'Carregando...',
    'generate': 'Gerar',
}

export default language