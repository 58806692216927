
import React from 'react';
import Grid from '@mui/material/Grid';
import Base from '../Base';
import ProfileCard from 'components/ProfileCard';
import InfoIcon from '@mui/icons-material/Info';
import { Alert, Box, Button, styled } from '@mui/material';
import { useNavigate, useLoaderData, Link } from 'react-router-dom';
import { User } from 'types/user.type';

import {FormattedMessage} from 'react-intl'

const RegisterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    padding: '0 0.5rem',
}))

const Profiles = () => {
    const navigate = useNavigate();
    const users = useLoaderData() as User[]


    const gotToNewProfile = () => {
        navigate('/profile')
    }

    
    return (
        <Base name={<FormattedMessage id="profiles" />} noBackButton>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', paddingY: 2, paddingX: 10 }}>
                <Button variant="contained" onClick={gotToNewProfile}><FormattedMessage id="add_profile" /></Button>
            </Box>
            <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {!users.length ? (
                    <Grid item xs={12} padding={2} >
                    <Alert sx={{ display: 'flex', justifyContent: 'center'}} icon={<InfoIcon fontSize="inherit" />} severity="info">
                        No profiles registered yet. 
                        <RegisterLink to="/profile">
                        Register here
                        </RegisterLink>
                    </Alert>
                    </Grid>
                ): (
                users.map((user: User, index: number) => (
                    <Grid item lg={3} md={6} xs={12} key={index}>
                    <ProfileCard key={index} user={user}></ProfileCard>
                    </Grid>
                )))}
            </Grid>
        </Base>
    )
}

export default Profiles