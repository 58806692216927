import React from 'react';
import { 
    Box, Button, Grid, TextField, Switch, FormGroup, 
    FormLabel, CircularProgress, Snackbar} from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';

import Base from "../Base"
import { generatePostApi, savePostApi } from "services/generate"
import { useLoaderData, useParams, useNavigate } from "react-router-dom";
import { Generated } from 'types/generated.type';
import GeneratedGroupData from 'components/GeneratedGoupData';
import DragDrop from 'components/DragDrop';
import { sendFileApi } from 'services/users';

import {FormattedMessage} from 'react-intl'


type iSwitch = {
    value: boolean,
    name: string,
    translate_id: string
}

type iValuesSwtich = {
    [key: string]: iSwitch
}


const GeneratePost: React.FC = () => {
    let { userId, chatId } = useParams();
    const navigate = useNavigate();
    const {files} = useLoaderData() as {files: string[]};

    const [uploadFiles, setUploadFiles] = React.useState<File[]>([]);
    const [filesLoaded, setFilesLoaded] = React.useState<string[]>(files);

    const switchesValues: iValuesSwtich = {
        generate_quotes: {value: false, name: "Quotes", translate_id: "quotes"},
        generate_memes: {value: false, name: "Memes", translate_id: "memes"},
        generate_funfacts: {value: false, name: "Fun Facts", translate_id: "fun_facts"},
        generate_benefitsfeatures: {value: false, name: "Benefits & Features", translate_id: "benefits_features"},
        generate_QandA: {value: false, name: "Q&A", translate_id: "q_a"},
        generate_humanityStories: {value: false, name: "Humanity Stories", translate_id: "humanity_stories"},
        generate_edCarrousel: {value: false, name: "Educational Carousel", translate_id: "educational_carousel"},
        generate_ctas: {value: false, name: "Call To Actions", translate_id: "call_to_actions"},
        generate_testimonial_reel: {value: false, name: "Testimonial Reel", translate_id: "testimonials_reel"},
        generate_testimonial_post: {value: false, name: "Testimonial Post", translate_id: "testimonials_post"},
        generate_inspirational: {value: false, name: "Inspirational", translate_id: "inspirational"},
        generate_reel_canvas: {value: false, name: "Reel Canvas", translate_id: "reel_canvas"},
        generate_videos_benefits: {value: false, name: "Videos & Benefits", translate_id: "videos_benefits"},
    }

    const [values, setValues] = React.useState<iValuesSwtich>(switchesValues)
    const [generated, setGenerated] = React.useState<Generated | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false)
    const [sbMessage, setSbMessage] = React.useState<string>('Posts saved!')

    const handleChangeSwitchValue = (switchValue: boolean, field: string) => {
        setValues({...values, [field]: {...values[field], value: switchValue}})
    }

    const handleGeneratePost = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true)
        const formData = new FormData(event.currentTarget);
        const tone = formData.get('tone') as string;
        const locations = formData.get('locations') as string;
        const switchs = Object.keys(values).filter((key: string) => values[key].value)
        const data = {
            files: files,
            ctas: [], //["visit our site", "Follow on Social Media", "Check out Blog", "Download App/eBook", "Newsletter"],
            locations: locations.split(',').map((location: string) => location.trim()),
            posts: switchs.map((switchValue: string) => {
                const postData: any = {
                    post_type: switchValue,
                    tone: tone,
                    user_id: userId,
                    special: ""
                }
                if (chatId) {
                    postData['chat_id'] = chatId
                }
                return postData
            })
        }

        try{
            const res = await generatePostApi(data);
            setGenerated(res.data)
        } catch(err) {
            console.error('err', err)
        }

        setLoading(false)
    }

    const handleUpload = (filesToUpload: FileList) => {
        const formData = new FormData();
        formData.append('file', filesToUpload[0]);
        setUploadFiles(uploadFiles.concat(Array.from(filesToUpload)))
        sendFileApi(userId || '', filesToUpload[0]).then(res => {
            const {file_path} = res.data
            // remove url base
            let url = file_path.split('user_docs')
            url = 'user_docs'+url.slice(1).join('')
            setFilesLoaded(filesLoaded.concat([url]))
        }).catch(err => {
            console.log('err', err)
        })
    }

    const handleSavePost = async () => {
        const data = {
            'user_id': userId,
            'data': generated
        }
        try{
            await savePostApi(data);
            setSbMessage('Posts saved!')
            navigate(`/posts/${userId}`)
        } catch(err) {
            console.error('err', err)
            setSbMessage(`Error saving posts!`)
        }
        setOpenSnackbar(true)        
    }


    return (
        <Base name={<FormattedMessage id="generate_post" />}>
            {generated ? (
                <>

                <Snackbar id="sb-save"
                    open={openSnackbar}
                    autoHideDuration={6000}
                    message={sbMessage}
                />                
                <Button variant="contained" color="primary" size="small" sx={{ paddingRight: '15px' }} onClick={handleSavePost}><SaveIcon/> Save</Button>
                <Button color="secondary" size="small" onClick={() => setGenerated(null)}><ReplayIcon/> Generate Again</Button>

                <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    {Object.keys(generated)
                        .filter((key: string) => !['questions', 'slides_captions'].includes(key))
                        .map(key => <GeneratedGroupData key={key} data={generated[key]} tag={key}/>)}
                </Box>
                </>
            )  : (
            <Box component="form" onSubmit={handleGeneratePost}
                sx={{
                    padding: 5,
                    paddingTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
                >
                <Grid container spacing={4}>
                    {Object.keys(values).map((key: string, index: number) => (
                        <Grid item xs={12} sm={6} md={2} key={index}>
                            <Button 
                                fullWidth
                                sx={{height: '100%'}}
                                variant={values[key].value ? "contained" : "outlined"} 
                                onClick={() => handleChangeSwitchValue(!values[key].value, key)}>
                                {values[key].translate_id ? (
                                    <FormattedMessage id={values[key].translate_id} defaultMessage={values[key].name}/>
                                )   : values[key].name}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={4} marginTop={1}>
                            <Grid item xs={12} md={8}>
                                <TextField fullWidth id="tone-field" label={<FormattedMessage id="tone" defaultMessage="Tone"/>} variant="outlined" name="tone"/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField 
                                    type="number" 
                                    defaultValue={1} 
                                    fullWidth 
                                    id="number-field" 
                                    label={<FormattedMessage id="number_of_posts" defaultMessage="Number of Posts"/>} 
                                    variant="outlined" 
                                    name="number_of_posts"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4} marginTop={1}>
                                <Grid item xs={12} md={2}>
                                    <FormLabel><FormattedMessage id="location_business" defaultMessage="Location Business"/></FormLabel>
                                    <FormGroup>
                                        <Switch defaultChecked name="location_business"/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <TextField fullWidth id="locations-field" 
                                        label={<FormattedMessage id="locations" defaultMessage="Locations"/>}
                                        variant="outlined" name="locations"
                                        helperText={<FormattedMessage id="enter_locations" defaultMessage="Enter the locations separated by commas"/>}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} paddingTop={2}>
                        <DragDrop onUpload={handleUpload} files={filesLoaded} setFiles={setUploadFiles} uploadedFiles={uploadFiles}></DragDrop>
                    </Grid>
                </Grid>
                <Box sx={{alignItems: 'end', display: 'flex', flexDirection: 'column', paddingRight: 15, width: '100%'}}>
                    <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>
                        {loading ? (
                            <>
                            {<FormattedMessage id="loading" defaultMessage="Loading"/>} <CircularProgress color="inherit" size={18} sx={{marginLeft: 1}}/>
                            </>
                        ): <><FormattedMessage id="generate" defaultMessage="Generate"/></> }
                    </Button>
                </Box>
            </Box>
            )}
        </Base>
    )
}

export default GeneratePost